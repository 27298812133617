import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import failGIF from "../images/fail.gif"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <img src={failGIF} alt="soccer player missing ball and falling down"/>
  </Layout>
)

export default IndexPage
